jQuery(function ($) {

    $('a[data-confirm]').click(function(ev) {
        var href = $(this).attr('href');
        if (!$('#dataConfirmModal').length) {
            $('body').append('<div id="dataConfirmModal" class="modal fade" tabindex="-1" role="dialog" aria-labelledby="dataConfirmLabel" aria-hidden="true">' +
                '<div class="modal-dialog"><div class="modal-content"><div class="modal-header"><button type="button" class="close" data-dismiss="modal" aria-hidden="true">×</button><h3 id="dataConfirmLabel">Confirmação de Exclusão</h3></div><div class="modal-body"></div><div class="modal-footer"><button class="btn btn-danger" data-dismiss="modal" aria-hidden="true">Não</button><a class="btn btn-success" id="dataConfirmOK">Sim</a></div></div></div></div>');
        }
        $('#dataConfirmModal').find('.modal-body').text($(this).attr('data-confirm'));
        $('#dataConfirmOK').attr('href', href);
        $('#dataConfirmModal').modal({show:true});
        return false;
    });

    $(".date").mask("99/99/9999", {placeholder: "__/__/____"});
    $('.phone').mask("(99) 9999-9999");
    $('.cel').mask("(99) 99999-9999");
    $('.cep').mask("99999-999");

    function limpa_formulário_cep() {
        // Limpa valores do formulário de cep.
        $("#endereco").val("");
        $("#bairro").val("");
        $("#cidade").val("");
        $("#uf").val("");

    }

    //Quando o campo cep perde o foco.
    $("#cep").blur(function () {

        //Nova variável "cep" somente com dígitos.
        var cep = $(this).val().replace(/\D/g, '');

        //Verifica se campo cep possui valor informado.
        if (cep != "") {

            //Expressão regular para validar o CEP.
            var validacep = /^[0-9]{8}$/;

            //Valida o formato do CEP.
            if (validacep.test(cep)) {

                //Consulta o webservice viacep.com.br/
                $.getJSON("//viacep.com.br/ws/" + cep + "/json/?callback=?", function (dados) {

                    if (!("erro" in dados)) {
                        //Atualiza os campos com os valores da consulta.
                        $("#endereco").val(dados.logradouro);
                        $("#bairro").val(dados.bairro);
                        $("#cidade").val(dados.localidade);
                        $("#uf").val(dados.uf);

                    } //end if.
                    else {
                        //CEP pesquisado não foi encontrado.
                        limpa_formulário_cep();
                    }
                });
            } //end if.
            else {
                //cep é inválido.
                limpa_formulário_cep();

            }
        } //end if.
        else {
            //cep sem valor, limpa formulário.
            limpa_formulário_cep();
        }
    });

});

function visualizaAssociado(associado) {
    $.ajax({
        type: 'GET',
        url: 'associado/show',
        data: {associado: associado},
        success: function (data) {
            $("#aNome").html(data.nome);
            $("#aTipo").html(data.tipo);
            $("#aMatricula").html(data.matricula);
            $("#aNascimento").html(data.nascimento);
            $("#aConjuge").html(data.conjuge);
            $("#aNascimentoConjuge").html(data.nascimentoconjuge);
            $("#aCep").html(data.cep);
            $("#aEndereco").html(data.endereco);
            $("#aBairro").html(data.bairro);
            $("#aCidade").html(data.cidade);
            $("#aTelefone").html(data.telefone);
            $("#aFax").html(data.fax);
            $("#aCelular").html(data.celular);
            $("#aEmail").html(data.email);
        },
        dataType: 'json'
    });
    $("#modalAssociado").modal('show');
}

function excluirAssociado(id){

}